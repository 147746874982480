/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:ed0f8953-e894-4445-ba06-e818c9259df1",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_3wvrPbdzS",
    "aws_user_pools_web_client_id": "6395md796duhiogi8sq2hmf45r",
    "oauth": {}
};


export default awsmobile;
